<template>
  <section>
    <v-sheet tile height="54" class="d-flex mb-3">
      <v-toolbar flat>
        <v-btn outlined class="mr-4" color="grey darken-2" @click="viewDay">
          Сьогодні
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          fab
          text
          small
          color="grey darken-2"
          @click="$refs.calendar.prev()"
        >
          <v-icon small> mdi-chevron-left</v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-btn
          fab
          text
          small
          color="grey darken-2"
          @click="$refs.calendar.next()"
        >
          <v-icon small> mdi-chevron-right</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
              <span>{{ typeToLabel[currentType] }}</span>
              <v-icon right> mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(type, key) in typeToLabel"
              :key="key"
              @click="choseTypeCalendar(key)"
            >
              <v-list-item-title>{{ type }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </v-sheet>
    <div v-if="!isLoading && !hasData" class="px-4 text-center">
      На жаль, за заданими Вами критеріями не було знайдено аукціонів, що мають відбутися у даному місяці. Будь ласка, поверніться на мапу та додайте інші населені пункти. Коли буде оголошено аукціони щодо ділянок у вибраних Вами населених пунктах, ми Вас сповістимо електронною поштою.
    </div>
    <div class="overflow-auto">
      <v-sheet min-width="900" :height="this.$vuetify.breakpoint.mdAndUp ? 800 : 600">
        <loader v-if="isLoading"/>
        <v-calendar
          v-else
          ref="calendar"
          :now="now"
          color="success"
          :show-month-on-first="false"
          v-model="selectedDate"
          :type="currentType"
          :events="modifiedEvents"
          :locale="locale"
          :locale-first-day-of-year="6"
          event-more-text="{0} ще"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          :event-color="getEventColor"
          @change="getEvents"
        />

        <v-dialog v-model="selectedOpen" scrollable>
          <v-card>
            <v-card-title class="pa-0">
              <v-toolbar :color="selectedEvent.color" dark>
                <v-toolbar-title v-if="selectedEvent.date">
                  {{ selectedEvent.date | date }}{{
                    ' - ' + selectedEvent.name
                  }}
                </v-toolbar-title>
                <v-spacer/>
                <v-icon @click="selectedOpen = false"> mdi-close</v-icon>
              </v-toolbar>
            </v-card-title>
            <v-card-text>
              <div v-if="selectedEvent.details">
                <div class="my-3 font-16" v-for="(item,idx) in selectedEvent.details" :key="idx">
                  <a :href="item.link" target="_blank"
                     class="text-dark">
                    <v-icon class="mr-2" color="success" v-if="item.icon">{{
                        item.icon
                      }}
                    </v-icon>
                    <span :style="dynamicColor('region')">{{ item.area }}, </span>
                    <span :style="dynamicColor('district')">{{ item.district }} р-н, </span>
                    <span class="text-success">{{ item.gromada }}, </span>
                    <b>{{ item.settlement }} </b>
                    <span>{{ item.text }}</span>
                  </a>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-sheet>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import mixinsDynamicStyles from '@/containers/Map/mixins'
import Loader from '@/shared/components/Loader'

export default {
  name: 'CalendarComponent',
  mixins: [mixinsDynamicStyles],
  components: { Loader },
  data: () => ({
    currentType: 'month',
    typeToLabel: {
      month: 'Місяць',
      week: 'Тиждень',
      day: 'День'
    },
    selectedDate: new Date(),
    now: new Date().toISOString().split('T')[0],
    locale: 'uk',
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    isLoading: false
  }),
  computed: {
    ...mapGetters('map', ['getCalendarAuctions']),
    modifiedEvents () {
      // Create a modified events array that includes all dates within the month
      // You can customize this logic to suit your specific use case
      if (this.currentType === 'month') {
        const date = new Date(this.selectedDate)
        const month = date.getMonth()
        const year = date.getFullYear()
        return this.events.filter(event => {
          const eventDate = new Date(event.date)
          return eventDate.getMonth() === month && eventDate.getFullYear() === year
        })
      }
      return this.events
    },
    hasData () {
      // Check if there is data available for the selected month
      // You can customize this logic to suit your specific use case
      return this.modifiedEvents.length > 0
    }
  },
  watch: {
    '$route.query.date': {
      handler (val) {
        if (val) {
          this.focus = val
          this.currentType = 'day'
          this.selectedOpen = false
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.isLoading = true
    this.fetchCalendarAuctions()
      .then(() => {
        this.getEvents()
      })
      .finally(() => {
        this.isLoading = false
      })
    const { type } = this.$route.query
    if (type) {
      this.currentType = type
    }
  },
  methods: {
    ...mapActions('map', ['fetchCalendarAuctions']),
    getEvents () {
      this.events = this.getCalendarAuctions
    },
    getEventColor (event) {
      return event.color
    },
    viewDay ({ date = this.now }) {
      this.focus = date
      this.choseTypeCalendar('day')
      const query = Object.assign({}, this.$route.query)
      query.date = date
      this.$router.push({ name: 'Auction', query }).catch(() => {
      })
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        )
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    choseTypeCalendar (key) {
      this.currentType = key
      this.$router.replace({ name: this.$route.name, query: { ...this.$route.query, type: key } }).catch(() => {
      })
    }
  }
}
</script>
